import { CUSTOM_ELEMENTS_SCHEMA, Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
// import { AppRoutingModule } from '../../app-routing.module';
import { Router } from '@angular/router';



@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, MatDividerModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  mobile: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit() {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  viewLinkedIn() {

  }

  viewTwitter() {

  }

  viewFaceBook() {

  }
  selectPartner() {
    this.router.navigateByUrl('partner-program');
    window.scrollTo(0, 0);
  }

  selectFeatures() {
    this.router.navigateByUrl('features');
    window.scrollTo(0, 0);
  }

  selectAboutUs() {
    this.router.navigateByUrl('about-us');
    window.scrollTo(0, 0);
  }

}
