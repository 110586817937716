<div class="header" *ngIf="!mobile">
  <img class="home" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
    src="../assets/images/reve-logo.png" />
  <nav>
    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a>
    <a routerLink="/features" routerLinkActive="active">Features</a>
    <!-- <a routerLink="/plans" routerLinkActive="active">Plans</a> -->
    <a routerLink="/about-us" routerLinkActive="active">About Us</a>
    <!-- <a routerLink="/blog" routerLinkActive="active">Blog</a> -->
    <a routerLink="/partner-program" routerLinkActive="active">Partner Program</a>
  </nav>
  <div>
    <button class="sign-up-button"
      onclick="Calendly.initPopupWidget({url: 'https://calendly.com/reve-cloud/introduction-to-reve'});return false;">
      Book A Demo
    </button>
  </div>
</div>

<div class="header" *ngIf="mobile">
  <img routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="home"
    src="../assets/images/reve-logo.png" />
  <div>
    <button mat-button [matMenuTriggerFor]="menu" class="menu-btn">
      <iconify-icon icon="cil:hamburger-menu" class="more-icon"></iconify-icon>
    </button>
    <mat-menu #menu="matMenu" class="menu-panel">
      <nav>
        <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a>
        <a routerLink="/features" routerLinkActive="active">Features</a>
        <!-- <a routerLink="/plans" routerLinkActive="active">Plans</a> -->
        <a routerLink="/about-us" routerLinkActive="active">About Us</a>
        <!-- <a routerLink="/blog" routerLinkActive="active">Blog</a> -->
        <a routerLink="/partner-program" routerLinkActive="active">Partner Program</a>
        <button class="sign-up-button"
          onclick="Calendly.initPopupWidget({url: 'https://calendly.com/reve-cloud/introduction-to-reve'});return false;">
          Book A Demo
        </button>
      </nav>
    </mat-menu>
  </div>
</div>