import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
{
    path: '',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent)
},
// {
//   path: 'home',
//   loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent)
// },
{
  path: 'plans',
  loadComponent: () => import('./pages/plans/plans.component').then(m => m.PlansComponent)
},
{
  path: 'features',
  loadComponent: () => import('./pages/features/features.component').then(m => m.FeaturesComponent)
},
{
  path: 'about-us',
  loadComponent: () => import('./pages/about-us/about-us.component').then(m => m.AboutUsComponent)
},
{
  path: 'blog',
  loadComponent: () => import('./pages/blog/blog.component').then(m => m.BlogComponent)
},
{
  path: 'partner-program',
  loadComponent: () => import('./pages/partner-program/partner-program.component').then(m => m.PartnerProgramComponent)
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
