<div class="footer" *ngIf="!mobile">
  <div class="footer-subpart1">
    <span class="logo-header">
      <img class="home" routerLink="/home" routerLinkActive="active" src="../assets/images/reve-logo.png" />
    </span>
    <!-- <div class="useful-link-container">
      <div class="footer-head">Useful Links</div>
      <div class="useful-links">
        <div class="useful-link">Our Solutions</div>
        <div class="useful-link">FinOps Management</div>
        <div class="useful-link">What We Believe In</div>
      </div>
    </div> -->
    <!-- <div class="social-media">
      <div class="footer-head">Social Media</div>
      <div class="links">
        <div>
          <iconify-icon icon="mingcute:linkedin-line" class="social-media-icon" (click)="viewLinkedIn()"></iconify-icon>
        </div>
        <div>
          <iconify-icon icon="la:twitter-square" style="color: white" class="social-media-icon"
            (click)="viewTwitter()"></iconify-icon>
        </div>
        <div>
          <iconify-icon icon="jam:facebook-square" style="color: white" class="social-media-icon"
            (click)="viewFaceBook()"></iconify-icon>
        </div>
      </div>

      <div class="footer-head">About Us</div>
      <div class="useful-links">
        <div class="useful-link">Why Choose Us?</div>
        <div class="useful-link">What We Believe In</div>
        <div class="useful-link">What we offer</div>
      </div>

    </div> -->
    <!-- <div class="subscription"> -->
    <!-- <div class="footer-head-subscribe">Stay Upto Date! We Send Out A Newsletter Monthly With Our Latest News</div>
      <div>
        <input type="text" placeholder="Enter your Email Id" class="text-area"><button class="subscribe-button">Sign
          Up</button>
      </div> -->
    <div class="quick-links">
      <div class="footer-head">Quick Links</div>
      <div class="useful-links">
        <div class="useful-link" (click)="selectPartner()">Our Partnership Programs</div>
        <div class="useful-link" (click)="selectFeatures()">Our Features</div>
        <div class="useful-link" (click)="selectAboutUs()">Our Team</div>
      </div>
    </div>
  </div>
  <!-- <mat-divider class="divider"></mat-divider>
  <div class="footer-subpart2">
    <div>
      Terms and conditions &nbsp;&nbsp;|&nbsp;&nbsp; Privacy Policy
    </div>
    <div>
      ©2022 by REVE
    </div>
  </div> -->
</div>

<div class="footer" *ngIf="mobile">
  <div class="footer-subpart1">
    <!-- <div class="useful-link-container">
      <div class="footer-head">Useful Links</div>
      <div class="useful-links">
        <div class="useful-link">Our Solutions</div>
        <div class="useful-link">FinOps Management</div>
        <div class="useful-link">What We Believe In</div>
      </div>
    </div> -->
    <!-- <div class="social-media">
      <div class="footer-head">Social Media</div>
      <div class="links">
        <div>
          <iconify-icon icon="mingcute:linkedin-line" class="social-media-icon" (click)="viewLinkedIn()"></iconify-icon>
        </div>
        <div>
          <iconify-icon icon="la:twitter-square" style="color: white" class="social-media-icon"
            (click)="viewTwitter()"></iconify-icon>
        </div>
        <div>
          <iconify-icon icon="jam:facebook-square" style="color: white" class="social-media-icon"
            (click)="viewFaceBook()"></iconify-icon>
        </div>
      </div>
      <div class="footer-head">About Us</div>
      <div class="useful-links">
        <div class="useful-link">Why Choose Us?</div>
        <div class="useful-link">What We Believe In</div>
        <div class="useful-link">What we offer</div>
      </div>

    </div> -->
    <!-- <div class="subscription">
      <div class="footer-head-subscribe">Stay Upto Date! We Send Out A Newsletter Monthly With Our Latest News</div>
      <div>
        <input type="text" placeholder="Enter your Email Id" class="text-area">
      </div>
      <div>
        <button class="subscribe-button">Sign Up</button>
      </div>
    </div>
  </div>
  <mat-divider class="divider"></mat-divider>
  <div class="footer-subpart2">
    <div>
      ©2022 by REVE
    </div>
    <div class="conditions">
      <span>
        Terms and conditions
      </span>
      <span>
        Privacy Policy
      </span>
    </div>
  </div> -->
    <div class="quick-links">
      <div class="footer-head">Quick Links</div>
      <div class="useful-links">
        <div class="useful-link" (click)="selectPartner()">Our Partnership Programs</div>
        <div class="useful-link" (click)="selectFeatures()">Our Features</div>
        <div class="useful-link" (click)="selectAboutUs()">Our Team</div>
      </div>
    </div>
    <div>
      <img class="home" routerLink="/home" routerLinkActive="active" src="../assets/images/reve-logo.png" />
    </div>
  </div>
</div>